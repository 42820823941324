import React, { useContext, useEffect, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
// import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { PageContext } from "../App";
import MapContext from "../Map/MapContext";

const ZoomLevels = () => {
  const { state, pageDispatcher } = useContext(PageContext);
  const { map } = useContext(MapContext);
  const { scale_data, slide_id: id, slidename: name, lens } = state;
  const [zoomLevel, setZoomLevel] = useState();
  const [previousZoomLevel, setPreviousZoomLevel] = useState();

  console.log('lens',lens)

  useEffect(() => {
    setPreviousZoomLevel(zoomLevel);
  }, [zoomLevel]);

  // const handleDrawCircle = () => {
  //   if (state.drawCircleStatus) {
  //     pageDispatcher.set_drawCircleStatus(false);
  //   } else {
  //     pageDispatcher.set_drawCircleStatus(true);
  //   }
  // };

  const handleResolutionClick = (resolutionVal, diameter, status) => {
    setPreviousZoomLevel(zoomLevel);
    setZoomLevel(resolutionVal);
    // pageDispatcher.set_Draw_Circle({ zoomValue: resolutionVal, previousZoomValue: zoomLevel, diameterValue: (diameter * scale_data).toFixed(2) });
    // pageDispatcher.set_Draw_Circle({ zoomValue: resolutionVal, previousZoomValue: zoomLevel });
    // if (status) {
    //   pageDispatcher.set_Draw_Circle({ diameterValue: (diameter * scale_data).toFixed(2) });
    // } else {
    //   pageDispatcher.set_Draw_Circle({ diameterValue: null });
    // }

    let resScale = scale_data / 5.275;
    const zoomDict = {
      "150X": 0.7 * resScale,
      "100X": 1.1 * resScale,
      "80X": 1.4 * resScale,
      "60X": 1.85 * resScale,
      "40X": 2.75 * resScale,
      "20X": 5.5 * resScale,
      "10X": 11 * resScale,
      "4X": 27.5 * resScale,
      fit: 106.34897959183674 * resScale,
    };

    map.getView().setResolution(zoomDict[resolutionVal].toString())
  };


  return (
    <div
      className="lens-zoom-buttons"
      style={{
        position: "absolute",
        right: "16px",
        top: "200px",
        margin: "0px",
        zIndex: "30",
        fontSize: "18px",
      }}
    >
      <ButtonGroup
        vertical
        size="sm"
        id="dynamicResolution"
        style={{ backgroundColor: "rgba(255,255,255,0.7)" }}
      >
        {lens === 100 ? (
          <>
            <Button
              variant="outline-secondary"
              value="150X"
              id="150X"
              onClick={() => handleResolutionClick("150X", 150, state.drawCircleStatus)}
              style={{
                cursor: "pointer",
              }}
            >
              150X
            </Button>
            <Button
              variant="outline-secondary"
              value="100X"
              id="100X"
              onClick={() => handleResolutionClick("100X", 220, state.drawCircleStatus)}
              style={{
                cursor: "pointer",
              }}
            >
              100X
            </Button>
          </>
        ) : null}
        <Button
          variant="outline-secondary"
          value="80X"
          id="80X"
          onClick={() => handleResolutionClick("80X", 250, state.drawCircleStatus)}
          style={{
            cursor: "pointer",
          }}
        >
          80X
        </Button>
        <Button
          variant="outline-secondary"
          value="60X"
          id="60X"
          onClick={() => handleResolutionClick("60X", 333, state.drawCircleStatus)}
          style={{
            cursor: "pointer",
          }}
        >
          60X
        </Button>
        <Button
          variant="outline-secondary"
          value="40X"
          id="40X"
          onClick={() => handleResolutionClick("40X", 500, state.drawCircleStatus)}
          style={{
            cursor: "pointer",
          }}
        >
          {" "}
          40X
        </Button>
        <Button
          variant="outline-secondary"
          value="20X"
          id="20X"
          onClick={() => handleResolutionClick("20X", 1000, state.drawCircleStatus)}
          style={{
            cursor: "pointer",
          }}
        >
          20X
        </Button>
        <Button
          variant="outline-secondary"
          value="10X"
          id="10X"
          onClick={() => handleResolutionClick("10X", 2000, state.drawCircleStatus)}
          style={{
            cursor: "pointer",
          }}
        >
          10X
        </Button>
        <Button
          variant="outline-secondary"
          value="4X"
          id="4X"
          onClick={() => handleResolutionClick("4X", 5000, state.drawCircleStatus)}
          style={{
            cursor: "pointer",
          }}
        >
          4X
        </Button>
        <Button
          variant="outline-secondary"
          value="fit"
          id="fit"
          onClick={() => handleResolutionClick("fit", 0, state.drawCircleStatus)}
          style={{
            cursor: "pointer",
          }}
        >
          Fit
        </Button>
        {/* <Button
          variant="outline-secondary"
          id="circle"
          // onClick={() => handleDrawCircle()}
          style={{
            cursor: "pointer",
          }}
        >
          {state.drawCircleStatus ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
        </Button> */}
      </ButtonGroup>
    </div>
  );
};

export default ZoomLevels;
