import axios from "axios";
import React, { createContext, useContext, useEffect, useReducer, useRef } from "react";
import "ol/ol.css";
import "./App.css";
import { LayerVector, Layers, TileDependentLayer } from "./Layers";
import Cropper from "cropperjs";
import { Button, Container, Image, Modal, Row } from "react-bootstrap";
import MapContainer from "./Map/MapContainer";
import { ZoomLevels, Zstackzoom } from "./components";

// let baseURL = "/";
let baseURL = window.location.href.split('?')[0];
let zoomifyURL = baseURL + "slidedata/";
let slideInfoURL = baseURL + "slideinfo/";

function App(props) {
  const initialState = {
    token: "",
    slide_id: "",
    slide_name: "",
    scale_data: "",
    annotation_list:[],
    tunnelURL: "",
    modalVisible: false,
    wsImage: "",
    disableTools: true,
    // zstack: "",
    zStackValue: 0,
    lens: "",
    numOfLayer: "",
    isSingleMode: true,
    selected_img_properties: {
      tileSize: "",
      imgHeight: "",
      imgWidth: "",
    },
  };

  const [state, dispatch] = useReducer(pageReducer, initialState);
  const pageDispatcher = {
    set_Slide_Details: (value) => dispatch({ type: "set_Slide_Details", payload: value }),
    set_disableTools: (value) => dispatch({ type: "set_disableTools", payload: value }),
    set_modalVisible: (value) => dispatch({ type: "set_modalVisible", payload: value }),
    set_Wsi_Image: (value) => dispatch({ type: "set_Wsi_Image", payload: value }),
    set_Lens_value: (value) => dispatch({ type: "set_Lens_value", payload: value }),
    set_zStackValue: (value) => dispatch({ type: "set_zStackValue", payload: value }),
    set_selected_img_properties: (value) => dispatch({ type: "set_selected_img_properties", payload: value }),
    set_scale_data: (value) => dispatch({ type: "set_scale_data", payload: value }),
    set_annotation_list: (value) => dispatch({ type: "set_annotation_list", payload: value }),
    set_numOfLayer: (value) => dispatch({ type: "set_numOfLayer", payload: value }),
    set_Layer_Mode: (value) => dispatch({ type: "set_Layer_Mode", payload: value }),
  };
  console.log("annotation_list:",state.annotation_list)

  useEffect(() => {
    let params = new URL(document.location).searchParams;
    let slide_id = params.get("slideid");
    let slide_token = params.get("accesstoken");
    let slide_name = params.get("slidename");

    if (slide_id && slide_token && slide_name) {
      pageDispatcher.set_Slide_Details({ slide_id: slide_id, token: slide_token, slide_name: slide_name });
    } else {
      alert("SlideId Error!");
    }
  }, []);

  const getImageProperties = async (slide_id, token) => {
    try {
      const res = await getSlideViewerData(slide_id, token);
      if (res?.data) {
        if (localStorage.getItem("x") == null && localStorage.getItem("y") == null) {
          localStorage.setItem("x", 100);
          localStorage.setItem("y", 100);
        }
        var parser = new DOMParser();
        var xmlDoc = parser.parseFromString(res.data, "text/xml");
        var elements = xmlDoc.getElementsByTagName("IMAGE_PROPERTIES");
        var tileSize = Number(elements[0].getAttribute("TILESIZE"));
        if (elements[0].getAttribute("WIDTH") != null && elements[0].getAttribute("HEIGHT") != null) {
          var imgWidth = Number(elements[0].getAttribute("WIDTH"));
          var imgHeight = Number(elements[0].getAttribute("HEIGHT"));
          pageDispatcher.set_selected_img_properties({ imgHeight, imgWidth, tileSize });
        }
      }
    } catch (error) {
      // NotificationManager.error("An error occurred", "Error", 3000);
      console.log(error);
    }
  };

  useEffect(() => {
    if (state.slide_id) {
      getImageProperties(state.slide_id, state.token);
    }
  }, [state.slide_id]);

  const get_scale_data = async (slide_id, token) => {
    pageDispatcher.set_scale_data({ scale_data: "5.27" });

    try {
      const res = await getScaleData(slide_id, token);
      if (res?.data) {
        let scale_data = res.data.scale;
        pageDispatcher.set_scale_data({ scale_data: scale_data });
        if (scale_data) pageDispatcher.set_disableTools({ disableTools: false });
      }
    } catch (error) {
      // NotificationManager.error("An error occurred", "Error", 3000);
      console.log(error);
    }
  };

  useEffect(() => {
    if (state.slide_id) {
      get_scale_data(state.slide_id, state.token);
    }
  }, [state.slide_id]);

  const get_annotation_List = async (slide_id, token) => {
    try {
      const res = await getannotationList(slide_id, token);
      if (res?.data) {
        let annotations = res.data.annotation_list;
        const transformedAnnotations = annotations.map((annotation) => {
          const annotationId = annotation.annotation_id;
          const slideId = annotation.slide_id;
          const color = annotation.color;
          const label = annotation.label;
          const created = annotation.created;
          const username = annotation.username;
          const rawData = JSON.parse(annotation.raw_data); // Parse the raw_data property
          const profileContent = JSON.parse(annotation.profile_content); // Parse the profile_content property
          // Create an object with the desired properties
          return {
            annotationId,
            slideId,
            color,
            label,
            created,
            username,
            rawData,
            profileContent,
          };
        });
        pageDispatcher.set_annotation_list({ annotation_list: transformedAnnotations });
      }
    } catch (error) {
      // NotificationManager.error("An error occurred", "Error", 3000);
      console.log(error);
    }
  };

  useEffect(() => {
    if (state.slide_id) {
      get_annotation_List(state.slide_id, state.token);
    }
  }, [state.slide_id]);

  useEffect(() => {
    if (!state.slide_id) return;
    getSlideInfo(state.slide_id, state.token)
      .then((res) => {
        if (res && res.data) {
          const updates = {};
          if (res.data.lens) {
            // updates.lens = res.data.lens;
            pageDispatcher.set_Lens_value({ lens: res.data.lens });
          }
          if (res.data.zstack) {
            pageDispatcher.set_numOfLayer({ numOfLayer: res.data.zstack });
            // updates.numOfLayer = res.data.zstack;
          } else {
            pageDispatcher.set_numOfLayer({ numOfLayer: 1 });
          }
          // if (Object.keys(updates).length > 0) {
          //   setState((prevState) => ({ ...prevState, ...updates }));
          // }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [state.slide_id, state.token]);

  // console.log("state", state.wsImage);

console.log('state disableTools',state.disableTools)


  return (
    <div className="container-fluid">
      <PageContext.Provider value={{ state, pageDispatcher }}>
        {state?.slide_id && (
          <MapContainer>
            {state?.selected_img_properties.tileSize && state?.scale_data && state.numOfLayer && (
              <Layers>
                <TileDependentLayer />
                {/* <TileDepend /> */}
                <LayerVector />
              </Layers>
            )}
            <OverViewComponent />
            <DrawFeaturesComponent />
            <ScreenshotSlideComponent />
            <ZoomLevels />
            {state?.numOfLayer > 1 && !state.isSingleMode && <Zstackzoom />}
            <div style={{ position: "absolute", zIndex: 200, background: "rgba(255, 255, 255, 0.7)" }}>SlideName: {state?.slide_name}</div>
          </MapContainer>
        )}
      </PageContext.Provider>
      {/* <PageContext.Provider value={{ state, pageDispatcher }}>{state && state.slide_id && <ViewComponent />}</PageContext.Provider> */}
    </div>
  );
}

export default App;

export const pageReducer = (state, action) => {
  switch (action.type) {
    case "set_Slide_Details":
      return {
        ...state,
        slide_id: action.payload.slide_id,
        token: action.payload.token,
        slide_name: action.payload.slide_name,
      };
    case "set_disableTools":
      return {
        ...state,
        disableTools: action.payload.disableTools,
      };
    case "set_scale_data":
      return {
        ...state,
        scale_data: action.payload.scale_data,
      };
    case "set_annotation_list":
      return {
        ...state,
        annotation_list: action.payload.annotation_list,
      };
    case "set_modalVisible":
      return {
        ...state,
        modalVisible: action.payload,
      };
    case "set_Wsi_Image":
      return {
        ...state,
        wsImage: action.payload,
      };
    case "set_Lens_value":
      return {
        ...state,
        lens: action.payload.lens,
      };
    case "set_numOfLayer":
      return {
        ...state,
        numOfLayer: action.payload.numOfLayer,
      };

    case "set_selected_img_properties":
      state = {
        ...state,
        selected_img_properties: {
          ...state.selected_img_properties,
          tileSize: action.payload.tileSize,
          imgHeight: action.payload.imgHeight,
          imgWidth: action.payload.imgWidth,
        },
      };
      return state;
    case "set_zStackValue":
      return {
        ...state,
        zStackValue: action.payload,
      };
    case "set_Layer_Mode":
      return {
        ...state,
        isSingleMode: action.payload,
      };

    default:
      return state;
  }
};

export const PageContext = createContext(null);

const OverViewComponent = () => {
  const { state, pageDispatcher } = useContext(PageContext);
  const imageElement = useRef(null);

  useEffect(() => {
    if (state.wsImage && imageElement.current) {
      new Cropper(imageElement.current, {
        viewMode: 0,
        dragMode: "move",
        zoomable: true,
        background: true,
        autoCrop: false,
        toggleDragModeOnDblclick: false,
      });
    }
  }, [state.wsImage]);

  const modalVisible = (val) => {
    // setState({ ...state, modalVisible: val })
    pageDispatcher.set_modalVisible(val);
  };

  console.log("state real outter", state.wsImage);

  const handleLinkClick = async () => {
    try {
      const res = await getannotated(state.slide_id, state.token);
      if (res && res.data && res.data.byteLength > 0) {
        let blob = new Blob([res.data], { type: res.headers["content-type"] });
        let blobURL = URL.createObjectURL(blob);

        const newWsImage = blobURL;

        // setState({ ...state, wsImage: blobURL, modalVisible: true })
        pageDispatcher.set_Wsi_Image(newWsImage);
        pageDispatcher.set_modalVisible(true);

        console.log("state inner", newWsImage);
        console.log("state real", state.wsImage);

        // new Cropper(imageElement.current, {
        //   viewMode: 0,
        //   dragMode: "move",
        //   //strict: true,
        //   zoomable: true,
        //   background: true,
        //   autoCrop: false,
        //   toggleDragModeOnDblclick: false,
        //   // crop: function (e) {
        //   // },
        // });
      } else {
        alert("No WS Image!");
      }
    } catch (error) {}
  };

  return (
    <>
      <a
        href="#!"
        style={{ position: "absolute",left: "50%",transform: "translateX(-50%)", zIndex: 210, background: "rgba(255, 255, 255, 0.7)" }}
        onClick={handleLinkClick}
      >
        <h4>OverView QS</h4>
      </a>

      {state && state.wsImage && (
        <Modal
          show={state.modalVisible}
          onHide={() => modalVisible(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>WS Image</Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            <Container>
              <Row>
                <Image
                  className="img-container"
                  src={state.wsImage}
                  id="img"
                  ref={imageElement}
                />
              </Row>
            </Container>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => modalVisible(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

const DrawFeaturesComponent = (props) => {
  // const { state, setState } = props.value;
  const { state, pageDispatcher } = useContext(PageContext);

  return (
    <div
      className="drawFeatures"
      style={{
        position: "absolute",
        right: "-1px",
        top: "42px",
        height: "400px",
        width: "65px",
        display: "inline-block",
        backgroundColor: "transparent",
        zIndex: "10",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="btn-group dropleft">
              <button
                type="button"
                className="btn btn-secondary"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                disabled={state.disableTools}
                style={{
                  cursor: state && state.disableTools ? "not-allowed" : "pointer",
                  backgroundColor: "rgba(0,60,136,0.5)",
                  color: "white",
                  padding: "0",
                  fontSize: "1.14em",
                  fontWeight: "bold",
                  textAlign: "center",
                  height: "1.375em",
                  width: "1.375em",
                  border: "none",
                  borderRadius: "2px",
                }}
              >
                <i
                  className="fa fa-paint-brush"
                  aria-hidden="true"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Measurement Type"
                ></i>
              </button>
              <div
                className="dropdown-menu"
                id="selectedElement"
                ref={props.selectedElement}
              >
                <a
                  className="dropdown-item"
                  href="#!"
                  id="length"
                >
                  Length (LineString)
                </a>
                <a
                  className="dropdown-item"
                  href="#!"
                  id="area"
                >
                  Area (Polygon)
                </a>
                <a
                  className="dropdown-item"
                  href="#!"
                  id="none"
                >
                  None
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ScreenshotSlideComponent = (props) => {
  // const { state, setState } = props.value;
  const { state, pageDispatcher } = useContext(PageContext);

  return (
    <div
      className="screenShot"
      style={{
        position: "absolute",
        right: "0px",
        top: "74px",
        height: "649px",
        width: "65px",
        display: "inline-block",
        backgroundColor: "transparent",
        zIndex: "10",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="btn-group dropleft">
              <button
                type="button"
                className="btn btn-secondary"
                id="export-jpeg-button"
                disabled={state.disableTools}
                style={{
                  cursor: state && state.disableTools ? "not-allowed" : "pointer",
                  backgroundColor: "rgba(0,60,136,0.5)",
                  color: "white",
                  padding: "0",
                  fontSize: "1.14em",
                  fontWeight: "bold",
                  textAlign: "center",
                  height: "1.375em",
                  width: "1.375em",
                  border: "none",
                  borderRadius: "2px",
                }}
              >
                <i
                  className="fa fa-arrow-circle-o-down"
                  aria-hidden="true"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Download current view"
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const getSlideViewerData = (slide_id, slide_token) => {
  return axios.get(zoomifyURL + slide_id + "/ImageProperties.xml", { headers: { Authorization: `Bearer ${slide_token}` } });
};
const getScaleData = (slide_id, slide_token) => {
  return axios.get(zoomifyURL + slide_id + "/scale.json", { headers: { Authorization: `Bearer ${slide_token}` } });
};
const getSlideInfo = (slide_id, slide_token) => {
  return axios.get(zoomifyURL + slide_id + "/slide_info.json", { headers: { Authorization: `Bearer ${slide_token}` } });
};

const getannotated = (slide_id, slide_token) => {
  return axios.get(baseURL + "getannotated" + "/" + slide_id, { headers: { Authorization: `Bearer ${slide_token}` }, responseType: "arraybuffer" });
};
const getannotationList = (slide_id, slide_token) => {
  console.log("getannotationList called:",slide_id, slide_token)
  return axios.post(baseURL + "getannotationList",{slide_id:slide_id}, { headers: { Authorization: `Bearer ${slide_token}` } });
};
