import TileState from "ol/TileState";
import View from "ol/View";
import { OverviewMap } from "ol/control";
import TileLayer from "ol/layer/WebGLTile.js";
import { Projection } from "ol/proj";
import Zoomify from "ol/source/Zoomify";
import { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { PageContext } from "../App";
import MapContext from "../Map/MapContext";
const TileDependentLayer = () => {
  const { state, pageDispatcher } = useContext(PageContext);
  const { slide_id: id, token, scale_data } = state;
  const { imgHeight, imgWidth, tileSize } = state.selected_img_properties;
  const { map } = useContext(MapContext);
  const source = useRef(null);
  const tileLayer = useRef(null);
  const overviewMapControl = useRef(null);
  const proj = useRef(null);

  const [layers, setLayers] = useState([]);

  let baseURL = window.location.href.split('?')[0];
  // let baseURL = "http://localhost:4000/";

  const createZoomifySource = (layer) => {
    return new Zoomify({
      //   url: baseURL + "slidedata/" + id + "/",
      url: `${baseURL}slidedata/${id}/${layer}/`,
      size: [imgWidth, imgHeight],
      crossOrigin: "anonymous",
      zDirection: -1,
      tileSize: tileSize / 1,
      tilePixelRatio: 1,
    });
  };

  const updateLayers = () => {
    const newLayers = [];
    const layerInfo = [];
    let n = state.numOfLayer || 1;
    for (let i = -Math.floor(n / 2); i <= Math.floor(n / 2); i++) {
      if (i !== 0) {
        layerInfo.push({ id: i, zIndex: i + Math.floor(n / 2), tagName: i });
      }
    }

    layerInfo.forEach((info) => {
      const source = createZoomifySource(info.id);

      source.setTileLoadFunction(async function (tile, src) {
        if (tile.getState() === TileState.LOADED) {
          return;
        }

        try {
          const response = await axios.get(src, {
            responseType: "blob",
            headers: {
              Authorization: "Bearer " + token,
            },
          });

          if (response.status === 200) {
            const data = response.data;
            tile.getImage().src = URL.createObjectURL(data);
          } else {
            tile.setState(TileState.ERROR);
          }
        } catch (error) {
          tile.setState(TileState.ERROR);
        }
      });
      const layer = new TileLayer({
        source: source,
        zIndex: info.zIndex,
        tagName: info.tagName,
        visible: false,
      });
      newLayers.push(layer);
    });
    setLayers(newLayers);
    return newLayers;
  };

  useEffect(() => {
    if (state.isSingleMode) {
      pageDispatcher.set_zStackValue(0);
      layers.forEach((layer) => {
        layer.setVisible(false);
      });
    } else {
      layers.forEach((layer) => {
        layer.setVisible(true);
      });
    }
  }, [state.isSingleMode]);

  useEffect(() => {
    if (!map) return;
    const layers = updateLayers();
    map.getLayers().extend(layers);
  }, [map]);

  const switchLayer = (tagName) => {
    // Get the index of the layer with the specified tag name.
    const layerIndex = layers.findIndex((layer) => layer.get("tagName") === tagName);
    if (tileLayer.current) {
      // const name = tileLayer.current.get("tagName");
      if (tagName === tileLayer.current.get("tagName")) {
        tileLayer.current.setZIndex(50);
      } else {
        tileLayer.current.setZIndex(49);
      }
    }

    if (layerIndex !== -1) {
      layers.forEach((layer) => {
        if (Number(layer.getZIndex()) === 50) layer.setZIndex(49);
      });
      layers[layerIndex].setZIndex(50);
    }
  };

  useEffect(() => {
    if (layers.length > 0) {
      switchLayer(state.zStackValue);
    }
  }, [state.zStackValue, layers]);

  useEffect(() => {
    if (!tileSize) return;
    source.current = new Zoomify({
      url: baseURL + "slidedata/" + id + "/",
      size: [imgWidth, imgHeight],
      crossOrigin: "anonymous",
      zDirection: -1,
      tileSize: tileSize / 1,
      tilePixelRatio: 1,
    });

    const variables = {
      exposure: 0,
      contrast: 0,
      saturation: 0,
      brightness: 0,
    };

    var extent = source.current.getTileGrid().getExtent();

    source.current.setTileLoadFunction(async function (tile, src) {
      if (tile.getState() === TileState.LOADED) {
        return;
      }

      try {
        const response = await axios.get(src, {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + token,
          },
        });

        if (response.status === 200) {
          const data = response.data;
          tile.getImage().src = URL.createObjectURL(data);
        } else {
          tile.setState(TileState.ERROR);
        }
      } catch (error) {
        tile.setState(TileState.ERROR);
      }
    });

    tileLayer.current = new TileLayer({
      style: {
        exposure: ["var", "exposure"],
        contrast: ["var", "contrast"],
        saturation: ["var", "saturation"],
        brightness: ["var", "brightness"],
        variables: variables,
      },
      source: source.current,
      zIndex: 50,
      tagName: 0,
    });

    let px_To_mmVal = 1 / scale_data;

    proj.current = new Projection({
      units: "pixels",
      extent: [0, 0, imgWidth, imgHeight],
      metersPerUnit: px_To_mmVal / 1000000, //(this is in micro unit)=>here converted px to mm & then mm to micro meter to get value
    });

    // setStyleToTiles(variables, tileLayer.current);

    map.setView(
      new View({
        resolutions: tileLayer.current.getSource().getTileGrid().getResolutions(),
        extent: extent,
        projection: proj.current,
        zoom: 7,
        constrainOnlyCenter: true,
        // center: [500000, 6000000],
      })
    );
    map.getView().fit(extent, map.getSize());

    map.addLayer(tileLayer.current);

    const handleZoomChange = () => {
      const resolution = map.getView().getResolution();
      let resScale = scale_data / 5.275;
      if (resolution <= Math.round(5.5 * resScale)) {
        pageDispatcher.set_Layer_Mode(false);
      } else {
        pageDispatcher.set_Layer_Mode(true);
      }
    };

    if (state.numOfLayer !== 1) {
      map.getView().on("change:resolution", handleZoomChange);
    }

    return () => {
      if (map) {
        map.removeLayer(tileLayer.current);
        map.getView().un("change:resolution", handleZoomChange);
        // map.removeControl(overviewMapControl.current);
      }
    };
  }, [map]);

  useEffect(() => {
    if (!source.current) return;
    // if (state.colorBox.togglePopup) {
    overviewMapControl.current = new OverviewMap({
      className: "ol-overviewmap ol-custom-overviewmap",
      layers: [
        new TileLayer({
          source: source.current,
        }),
      ],
      collapseLabel: "\u00BB",
      label: "\u00AB",
      collapsible: true,
      collapsed: false,
      view: new View({
        resolutions: tileLayer.current.getSource().getTileGrid().getResolutions(),
        extent: [imgWidth, 0, 0, -imgHeight],
        projection: scale_data ? proj.current : "",
        constrainOnlyCenter: true,
        maxZoom: 0,
        minZoom: 0,
        zoom: 0,
      }),
    });

    overviewMapControl.current.getOverviewMap().on("click", function (event) {
      map.getView().setCenter(event.coordinate);
    });

    map.addControl(overviewMapControl.current);
    // }
    return () => {
      if (map) {
        map.removeControl(overviewMapControl.current);
      }
    };
  }, [map]);

  return null;
};

export default TileDependentLayer;
