import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import MapContext from "../Map/MapContext";
import { PageContext } from "../App";

const ZstackZoom = () => {
  const { map } = useContext(MapContext);
  const { state, pageDispatcher } = useContext(PageContext);
  const numLayers = state.numOfLayer;
  const max = +Number(Math.floor(numLayers / 2));
  const min = -Number(Math.floor(numLayers / 2));
  const { scale_data } = state;

  const handleButtonClick = (increment) => {
    console.log("777 handleButtonClick:",state.zStackValue, typeof state.zStackValue)
    pageDispatcher.set_zStackValue(state.zStackValue + (increment ? 1 : -1));
  };

  console.log("777 numLayers:",numLayers)
  console.log("888 isSingleMode:",state.isSingleMode)
  console.log("777 state.zStackValue:",state.zStackValue)

  return (
    <div
      className='zStackZoom'
      style={{
        position: "absolute",
        right: "16px",
        top: "519px",
        margin: "0px",
        zIndex: "30",
        fontSize: "18px",
        backgroundColor: "rgba(255,255,255,0.7)",
      }}
    >
      <div className="d-flex flex-column">
        <Button
          variant="outline-secondary"
          onClick={() => handleButtonClick(true)}
          style={{
            cursor: "pointer",
          }}
          disabled={max === state.zStackValue}
        >
          +
        </Button>
        {state.zStackValue}
        <Button
          variant="outline-secondary"
          onClick={() => handleButtonClick(false)}
          style={{
            cursor: "pointer",
          }}
          disabled={min === state.zStackValue}
        >
          -
        </Button>
      </div>
    </div>
  );
};

export default ZstackZoom;

