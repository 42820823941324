import { unByKey } from "ol/Observable";
import Overlay from "ol/Overlay";
import { LineString, Polygon } from "ol/geom";
import Draw from "ol/interaction/Draw";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Fill, Stroke, Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import { useContext, useEffect, useRef } from "react";
import { PageContext } from "../App";
import MapContext from "../Map/MapContext";
import { Feature } from "ol";
import Circle from "ol/geom/Circle";
import Point from "ol/geom/Point";




const LayerVector = () => {
  const { map } = useContext(MapContext);
  const { state, pageDispatcher } = useContext(PageContext);
  const { scale_data, slide_id } = state;
  const vector_layer = useRef(null);
  let draw, measureTooltipElement, measureTooltip, helpTooltipElement, helpTooltip, sketch;
  const continueLineMsg = "Click to continue drawing the line";
  const continuePolygonMsg = "Click to continue drawing the polygon";

  const polygonArea = (X, Y, n) => {
    let area = 0.0;
    let j = n - 1;
    for (let i = 0; i < n; i++) {
      area += (X[j] + X[i]) * (Y[j] - Y[i]);
      j = i;
    }

    let val = Math.abs(area / 2.0);

    if (!Number.isInteger(val)) {
      val.toFixed(2);
    }

    let output;
    if (scale_data) {
      output = `${(val / Math.pow(scale_data, 2)).toFixed(2)} µm<sup>2</sup>`;
    } else {
      output = `${val} px<sup>2</sup>`;
    }

    return output;
  };

  const lineStringLength = (lineCoords) => {
    let output, data;
    let dataarr = [];
    for (let i = 0; i < lineCoords.length; i++) {
      let xn = lineCoords[i][0];
      let yn = lineCoords[i][1];

      if (lineCoords[i + 1] !== undefined) {
        let xinc = lineCoords[i + 1][0];
        let yinc = lineCoords[i + 1][1];

        data = Math.sqrt(Math.pow(xinc - xn, 2) + Math.pow(yinc - yn, 2));
        dataarr.push(data);
      }
    }

    let sum = dataarr.reduce(function (accumulator, currentValue) {
      return accumulator + currentValue;
    });

    let val = Math.round(sum);

    if (!Number.isInteger(val)) {
      val.toFixed(2);
    }

    if (scale_data) {
      output = `${(val / scale_data).toFixed(2)} µm`;
    } else {
      output = `${val} px`;
    }

    return output;
  };

  // const formatLength = function (line) {
  //   const length = getLength(line);
  //   // console.log("length:",length)
  //   let output;
  //   if (!Number.isInteger(length)) {
  //     length.toFixed(2);
  //   }

  //   if (scale_data) {
  //     output = `${(length / scale_data).toFixed(2)} µm`;
  //   } else {
  //     output = `${length} px`;
  //   }
  //   return output;
  // };

  function createMeasureTooltip() {
    if (measureTooltipElement) {
      measureTooltipElement.parentNode.removeChild(measureTooltipElement);
    }
    measureTooltipElement = document.createElement("div");
    measureTooltipElement.className = "ol-tooltip ol-tooltip-measure";
    measureTooltip = new Overlay({
      element: measureTooltipElement,
      offset: [0, -15],
      positioning: "bottom-center",
      stopEvent: false,
      insertFirst: false,
    });
    map.addOverlay(measureTooltip);
  }

  function createHelpTooltip() {
    if (helpTooltipElement) {
      helpTooltipElement.parentNode.removeChild(helpTooltipElement);
    }
    helpTooltipElement = document.createElement("div");
    helpTooltipElement.className = "ol-tooltip hidden";

    helpTooltip = new Overlay({
      element: helpTooltipElement,
      offset: [15, 0],
      positioning: "center-left",
    });
    map.addOverlay(helpTooltip);
  }

  const pointerMoveHandler = function (evt) {
    createMeasureTooltip();
    createHelpTooltip();

    if (evt.dragging) {
      return;
    }

    let helpMsg = "Click to start drawing";

    if (sketch) {
      const geom = sketch.getGeometry();
      if (geom instanceof Polygon) {
        helpMsg = continuePolygonMsg;
      } else if (geom instanceof LineString) {
        helpMsg = continueLineMsg;
      }

      helpTooltipElement.innerHTML = helpMsg;
      helpTooltip.setPosition(evt.coordinate);
      helpTooltipElement.classList.remove("hidden");
    }
  };

  function addInteraction(selectedElement) {
    if (selectedElement && selectedElement.id) {
      if (selectedElement.id !== "none") {
        let type = selectedElement.id === "area" ? "Polygon" : "LineString";

        draw = new Draw({
          source: vector_layer.current.getSource(),
          type: type,
          style: new Style({
            fill: new Fill({
              color: "rgba(255, 255, 255, 0.2)",
            }),
            stroke: new Stroke({
              color: "red",
              lineDash: [10, 10],
              width: 2,
            }),
            image: new CircleStyle({
              radius: 5,
              stroke: new Stroke({
                color: "yellow",
              }),
              fill: new Fill({
                color: "#ffcc33",
              }),
            }),
          }),
        });

        createMeasureTooltip();
        createHelpTooltip();
        let listener;

        draw.on("drawstart", function (evt) {
          // set sketch
          sketch = evt.feature; //getDrawnFeatureObj i.e Feature{..}
          listener = sketch.getGeometry().on("change", function (event) {});
        });

        draw.on("drawend", function (event) {
          let geomType = event.feature.getGeometry();
          let coordinates, output, tooltipCoord;
          let arr = [];
          let X, Y;
          let Xarr = [],
            Yarr = [];

          if (geomType.getType() === "Polygon") {
            coordinates = event.feature.getGeometry().getCoordinates();
            coordinates = coordinates[0];
            coordinates.forEach((el) => {
              // pixelCoord = initialMap.getPixelFromCoordinate([el[0], el[1]]);
              X = Math.round(el[0]);
              Y = Math.round(el[1]);
              arr.push([X, Y]);
            });
            // console.log("arr", arr)
            arr.forEach((el) => {
              Xarr.push(el[0]);
              Yarr.push(el[1]);
            });

            output = polygonArea(Xarr, Yarr, Xarr.length);
            tooltipCoord = geomType.getInteriorPoint().getCoordinates();

            // console.log("output of Polygon", output)
          } else if (geomType.getType() === "LineString") {
            coordinates = event.feature.getGeometry().getCoordinates();
            coordinates.forEach((el) => {
              // pixelCoord = initialMap.getPixelFromCoordinate([el[0], el[1]]);

              // X = Math.round(pixelCoord[0]);
              // Y = Math.round(pixelCoord[1]);

              X = Math.round(el[0]);
              Y = Math.round(el[1]);

              arr.push([X, Y]);
            });
            output = lineStringLength(arr);
            // console.log("output of Linestring", output)
            tooltipCoord = geomType.getLastCoordinate();
          }
          measureTooltipElement.innerHTML = output;
          measureTooltip.setPosition(tooltipCoord);

          measureTooltipElement.className = "ol-tooltip ol-tooltip-static";
          measureTooltip.setOffset([0, -7]);
          // unset sketch
          sketch = null;
          // unset tooltip so that a new one can be created
          measureTooltipElement = null;
          createMeasureTooltip();
          unByKey(listener);
        });
        map.addInteraction(draw);
      }
    }
  }

  useEffect(() => {
    if (!map) return;

    const styleFunction = (feature) => {
      const color = feature.get("color");
      return new Style({
        image: new CircleStyle({
          radius: 4,
          fill: new Fill({
            color: color,
          }),
          stroke: new Stroke({
            color: color, // Stroke color for the circle's outline
            width: 2,
          }),
        }),
        stroke: new Stroke({
          color: color,
          width: 2,
        }),
      });
    };

    vector_layer.current = new VectorLayer({
      name: "my_vectorlayer",
      // source: source,
      source: new VectorSource({
        wrapX: false,
      }),
      zIndex: 100,
      style: styleFunction,
      // style: new Style({
      //   fill: new Fill({
      //     color: "rgba(255, 255, 255, 0.2)",
      //   }),
      //   stroke: new Stroke({
      //     // color: `rgb(${r},${g},${b})`,
      //     width: 2,
      //   }),
      //   image: new CircleStyle({
      //     radius: 7,
      //     fill: new Fill({
      //       color: "#ff0000",
      //     }),
      //   }),
      // }),
    });
    map.addLayer(vector_layer.current);

    return () => {
      map.removeLayer(vector_layer.current);
    };
  }, [slide_id]);

  useEffect(() => {
    if (!map) return;
    var typeSelect = document.getElementById("selectedElement");
    if (typeSelect) {
      typeSelect.addEventListener("click", function (event) {
        map.removeInteraction(draw);

        if (event.target.id !== "none") {
          map.on("pointermove", pointerMoveHandler);
          createMeasureTooltip();
          createHelpTooltip();
          addInteraction(event.target);
        } else {
          sketch = null;
          map.removeOverlay(helpTooltip);
          map.removeOverlay(measureTooltip);
        }
      });
    }
  }, []);

  function getLastCoordinates(coordinatesList) {
    const lastCoordinate = coordinatesList[coordinatesList.length - 1];
    if (Array.isArray(lastCoordinate[0])) {
      return lastCoordinate[lastCoordinate.length - 1];
    } else {
      return lastCoordinate;
    }
  }

  const createCommentTooltip = (coord, comment, uiqueIdComment) => {
    const tooltipElement = document.createElement("div");
    tooltipElement.className = "ol-tooltip ol-tooltip-comment";
    tooltipElement.innerHTML = comment;

    const tooltip = new Overlay({
      element: tooltipElement,
      positioning: "center-left",
      offset: [0, 0],
      stopEvent: false,
      insertFirst: false,
    });
    tooltip.customProperty = { comment_toolTip_id: uiqueIdComment };
    map.addOverlay(tooltip);
    tooltip.setPosition(coord);
    tooltipElement.style.display = "block";
  };

  const createAnnotMeasureTooltip = (coord, output, uiqueIdMeasure) => {
    let measureTooltipElement = document.createElement("div");
    measureTooltipElement.className = "ol-tooltip ol-tooltip-measure";
    measureTooltipElement.innerHTML = output;

    let measureTooltip = new Overlay({
      element: measureTooltipElement,
      offset: [0, -15],
      positioning: "bottom-center",
      stopEvent: false,
      insertFirst: false,
    });
    measureTooltip.customProperty = { masure_toolTip_id: uiqueIdMeasure };
    map.addOverlay(measureTooltip);
    measureTooltip.setPosition(coord);
    measureTooltipElement.style.display = "block";
  };

  useEffect(() => {
    if (!map || !vector_layer.current) return;
    const vectorSource = vector_layer.current.getSource();
    vectorSource.clear();
    map.once("rendercomplete", () => {
      map.getOverlays().clear();

      state.annotation_list.forEach((coords) => {
        console.log("coords:::",coords)
        if (coords && coords.rawData.raw_data.coordinates.length > 0 && coords.rawData.raw_data.type === "LineString") {
          // console.log("rendercomplete lineString")
          const lineFeature = new Feature({ geometry: new LineString(coords.rawData.raw_data.coordinates), color: coords.color });
          lineFeature.set(
            "annotation_id",
            coords.annotationId,
            "annotation_hide",
            coords.profileContent.annotation_hide,
            "annotation_comment",
            coords.profileContent.annotation_comment,
            "annotation_measurement",
            coords.profileContent.annotation_measurement
          );
          if (coords.profileContent.annotation_hide === "0") {
            vectorSource.addFeature(lineFeature);
            if (coords.profileContent.annotation_comment === "0" && coords.rawData.raw_data.comment) {
              const lastCoordinates2 = getLastCoordinates(coords.rawData.raw_data.coordinates);
              createCommentTooltip(lastCoordinates2, coords.rawData.raw_data.comment, coords.annotationId);
            }
            if (coords.profileContent.annotation_measurement === "0") {
              const lastCoordinates2 = getLastCoordinates(coords.rawData.raw_data.coordinates);
              createAnnotMeasureTooltip(lastCoordinates2, coords.rawData.raw_data.length, coords.annotationId);
            }
          }
        } else if (coords && coords.rawData.raw_data.coordinates.length > 0 && coords.rawData.raw_data.type === "Polygon") {
          // console.log("rendercomplete Polygon")

          const polygonFeature = new Feature({ geometry: new Polygon([coords.rawData.raw_data.coordinates[0]]), color: coords.color });
          const overlayCoords = polygonFeature.getGeometry().getInteriorPoint().getCoordinates();
          polygonFeature.set(
            "annotation_id",
            coords.annotationId,
            "annotation_hide",
            coords.profileContent.annotation_hide,
            "annotation_comment",
            coords.profileContent.annotation_comment,
            "annotation_measurement",
            coords.profileContent.annotation_measurement
          );
          if (coords.profileContent.annotation_hide === "0") {
            vectorSource.addFeature(polygonFeature);
            if (coords.profileContent.annotation_comment === "0" && coords.rawData.raw_data.comment) {
              createCommentTooltip(overlayCoords, coords.rawData.raw_data.comment, coords.annotationId);
            }
            if (coords.profileContent.annotation_measurement === "0") {
              createAnnotMeasureTooltip(overlayCoords, coords.rawData.raw_data.length, coords.annotationId);
            }
          }
        } else if (coords && coords.rawData.raw_data.coordinates.length > 0 && coords.rawData.raw_data.type === "Circle") {
          // console.log("rendercomplete Circle:",coords.rawData.raw_data.coordinates[0],coords.rawData.raw_data.radius)

          const circleFeature = new Feature({
            geometry: new Circle(coords.rawData.raw_data.coordinates[0], coords.rawData.raw_data.radius),
            color: coords.color,
          });
          const overlayCoords = circleFeature.getGeometry().getCenter();
          circleFeature.set(
            "annotation_id",
            coords.annotationId,
            "annotation_hide",
            coords.profileContent.annotation_hide,
            "annotation_comment",
            coords.profileContent.annotation_comment,
            "annotation_measurement",
            coords.profileContent.annotation_measurement
          );
          if (coords.profileContent.annotation_hide === "0") {
            vectorSource.addFeature(circleFeature);
            if (coords.profileContent.annotation_comment === "0" && coords.rawData.raw_data.comment) {
              createCommentTooltip(overlayCoords, coords.rawData.raw_data.comment, coords.annotationId);
            }
            if (coords.profileContent.annotation_measurement === "0") {
              createAnnotMeasureTooltip(overlayCoords, coords.rawData.raw_data.length, coords.annotationId);
            }
          }
        } else if (coords && coords.rawData.raw_data.coordinates.length > 0 && coords.rawData.raw_data.type === "Point") {
          // console.log("rendercomplete Point")
          const pointFeature = new Feature({ geometry: new Point(coords.rawData.raw_data.coordinates), color: coords.color });
          pointFeature.set(
            "annotation_id",
            coords.annotationId,
            "annotation_hide",
            coords.profileContent.annotation_hide,
            "annotation_comment",
            coords.profileContent.annotation_comment,
            "annotation_measurement",
            coords.profileContent.annotation_measurement
          );
          if (coords.profileContent.annotation_hide === "0") {
            vectorSource.addFeature(pointFeature);
            if (coords.profileContent.annotation_comment === "0" && coords.rawData.raw_data.comment) {
              const lastCoordinates2 = getLastCoordinates([coords.rawData.raw_data.coordinates]);
              createCommentTooltip(lastCoordinates2, coords.rawData.raw_data.comment, coords.annotationId);
            }
            // if (coords.profileContent.annotation_measurement === "0") {
            //   const lastCoordinates2 = getLastCoordinates([coords.rawData.raw_data.coordinates]);
            //   createAnnotMeasureTooltip(lastCoordinates2, coords.rawData.raw_data.length, coords.annotationId);
            // }
          }
        }
      });
    });
  }, [state.annotation_list,map]);

  return null;
};

export default LayerVector;
