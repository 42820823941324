import Map from "ol/Map";
import { Control, FullScreen, ScaleLine, defaults as defaultControls } from "ol/control";
import { useContext, useEffect, useRef, useState } from "react";
import { PageContext } from "../App";
import MapContext from "./MapContext";
import { DragRotateAndZoom, defaults as defaultInteractions } from "ol/interaction";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";

const MapContainer = ({ children, prepareSlideViewerData }) => {
  const { state, pageDispatcher } = useContext(PageContext);
  const { slide_id: id, token, scale_data, slidename: name } = state;

  const mapRef = useRef();
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (!id) return;
    var map = new Map({
      interactions: defaultInteractions().extend([new DragRotateAndZoom()]),
      loadTilesWhileAnimating: true,
      loadTilesWhileInteracting: true,
    });

    map.setTarget(mapRef.current);

    setMap(map);

    return () => map.setTarget(null);
  }, [id]);

  useEffect(() => {
    if (!map) return;
    const scaleControl = new ScaleLine({
      units: "metric",
      className: "ol-scale-line",
    });

    if (scale_data) {
      map.addControl(scaleControl);
      map.addControl(new FullScreen());
    }
  }, [map, scale_data]);

  useEffect(() => {
    if (!map) return;
    const copyrightControl = new Control({
      element: document.createElement('div'),
      render: function() {
        this.element.innerHTML = '&copy; Micalys';
        this.element.style.cssText = 'position: absolute; bottom: 0; right: 0; padding: 5px; background: rgba(255, 255, 255, 0.7); font-size: 15px;';
      }
    });

    map.addControl(copyrightControl);
  }, [map]);


  let exp_btn = document.getElementById("export-jpeg-button");

  if (exp_btn) {
    exp_btn.onclick = async () => {
      doScreenshot();
    };
  }

  async function doScreenshot() {
    const mapElement = mapRef.current;

    // Temporarily hide unwanted elements
    const zoomControls = document.querySelector(".ol-zoom");
    const fullScreenControl = document.querySelector(".ol-full-screen");
    const lensZoomButtons = document.querySelector(".lens-zoom-buttons");
    const screenShot = document.querySelector(".screenShot");
    const drawFeatures = document.querySelector(".drawFeatures");

    // const overviewMap = document.querySelector(".ol-overviewmap");
    // const scaleLine = document.querySelector(".ol-scale-line");
    
    fullScreenControl.style.display = "none";
    zoomControls.style.display = "none";
    lensZoomButtons.style.display = "none";
    screenShot.style.display = "none";
    drawFeatures.style.display = "none";

    //  overviewMap.style.display = "none";
    //  scaleLine.style.display = "none";

    html2canvas(mapElement).then((canvas) => {
      // Restore visibility of elements
      zoomControls.style.display = "block";
      fullScreenControl.style.display = "block";
      lensZoomButtons.style.display = "block";
      screenShot.style.display = "block";
      drawFeatures.style.display = "block";

      // overviewMap.style.display = "block";
      // scaleLine.style.display = "block";

      canvas.toBlob((blob) => {
        saveAs(blob, "map-screenshot.png");
      });
    });
  }

  return (
    <MapContext.Provider value={{ map }}>
      <div
        ref={mapRef}
        className="map"
        id="main-map"
      >
        {children}
      </div>
    </MapContext.Provider>
  );
};

export default MapContainer;
